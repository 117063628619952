<template>
  <div
    class="relative flex flex-col items-center justify-center px-4 pb-11 pt-6 lg:px-10 lg:pb-14"
    :style="{ backgroundColor: data.bgColor }"
  >
    <h2
      class="text-center font-serif text-4xl font-bold leading-none text-black lg:text-5xl lg:leading-none"
    >
      {{ data.title }}
    </h2>
    <div class="absolute -bottom-6 lg:-bottom-8">
      <Link
        :title="data.buttonLabel"
        :link="data.url"
        :color="buttonType.color"
        :type="buttonType.type"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Link from '../../atoms/Link.vue'

interface ICampaignButton {
  title: string
  buttonLabel: string
  bgColor: string
  url: string
  buttonType: 'pink-fill' | 'pink-outline' | 'blue-fill' | 'blue-outline'
}

interface Props {
  data: ICampaignButton
}

const props = defineProps<Props>()

interface IButtonType {
  color: 'blue' | 'pink'
  type: 'fill' | 'outline'
}

const buttonType = computed<IButtonType>(() => {
  if (props.data.buttonType === 'pink-fill') {
    return { color: 'pink', type: 'fill' }
  }

  if (props.data.buttonType === 'pink-outline') {
    return { color: 'pink', type: 'outline' }
  }

  if (props.data.buttonType === 'blue-fill') {
    return { color: 'blue', type: 'fill' }
  }

  if (props.data.buttonType === 'blue-outline') {
    return { color: 'blue', type: 'outline' }
  }

  return { color: 'blue', type: 'fill' }
})
</script>
